import { H1 } from "@casavo/habitat"
import useTranslation from "next-translate/useTranslation"
import React from "react"

import { firstTitle, fitContent, h4, heroTitle, secondTitle } from "./HeroTitle.css"

interface HeroTitleProps {
  noMargin?: boolean
  subtitle: string
  title: string
}

export const HeroTitle: React.FC<HeroTitleProps> = ({ noMargin, subtitle, title }) => {
  const title2 = title.split("<br>")
  const { lang } = useTranslation()

  return (
    <section className={heroTitle({ noMargin })}>
      <div className={fitContent}>
        <div className={firstTitle({ noMargin, size: lang === "en" || noMargin })}>
          <H1>{title2[0]}</H1>
        </div>
        <div className={secondTitle({ size: lang === "en" || noMargin })}>
          <H1>{title2[1]}</H1>
        </div>
      </div>
      <div className={h4}>{subtitle}</div>
    </section>
  )
}
